import { Address } from 'wagmi'

import { API_URLS } from '../constants'
import API from './api'

type GetAuthMessageResult = {
  message: string
}

export const getAuthMessage = async (address: Address): Promise<string> => {
  const { data } = await API.get<GetAuthMessageResult>(
    `${API_URLS.ZEXUS_API}/users/auth-message/${address}/`
  )
  return data.message
}

export type GetAuthTokenVars = {
  address: Address
  signature: string
}

type GetAuthTokenParams = {
  signature: string
}

type GetAuthTokenResult = {
  token: string
}

export const getAuthToken = async (variables: GetAuthTokenVars): Promise<string> => {
  const params: GetAuthTokenParams = {
    signature: variables.signature,
  }
  const { data } = await API.post<GetAuthTokenResult>(
    `${API_URLS.ZEXUS_API}/users/auth-token/${variables.address}/`,
    params
  )
  return data.token
}
