import { configureStore } from '@reduxjs/toolkit'

import authSliceReducer from './slices/auth/auth.slice'
import collateralSliceReducer from './slices/collaterals/collaterals.slice'

export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    collaterals: collateralSliceReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type RequestStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected'
