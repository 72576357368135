export enum SortOptions {
  newest = 'Newest',
  oldest = 'Oldest',
  desc_amount = 'Descending principal amount',
  asc_amount = 'Ascending principal amount',
  latest_due_date = 'Latest due date',
  earliest_due_date = 'Earlies due date',
}

export type SortItem = keyof typeof SortOptions
