import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import {
  CollateralDetailScreen,
  CollateralsScreen,
  DemoScreen,
  Error404Screen,
  HomeScreen,
  LoanDetailScreen,
  LoansScreen,
  ProfileCollateralsScreen,
  ProfileLoansScreen,
  ProfileOffersScreen,
  ProfileScreen,
} from 'screens'

import { Footer, Navigation } from 'components'

const AppContainer = () => (
  <Router>
    <Navigation />
    <main className="pt-1">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="collaterals/:id" element={<CollateralDetailScreen />} />
        <Route path="collaterals" element={<CollateralsScreen />} />
        <Route path="loans/:id" element={<LoanDetailScreen />} />
        <Route path="loans" element={<LoansScreen />} />
        <Route path="profile" element={<ProfileScreen />}>
          <Route index element={<ProfileLoansScreen />} />
          <Route path="loans" element={<ProfileLoansScreen />} />
          <Route path="collaterals" element={<ProfileCollateralsScreen />} />
          <Route path="offers-made" element={<ProfileOffersScreen />} />
          <Route path="offers-received" element={<ProfileOffersScreen />} />
        </Route>
        <Route path="demo" element={<DemoScreen />} />
        <Route path="*" element={<Error404Screen />} />
      </Routes>
    </main>
    <Footer />
  </Router>
)

export default AppContainer
