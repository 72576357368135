import { FC, PropsWithChildren } from 'react'

import { Address } from 'wagmi'

import { shortAddress } from 'utils/address'

import { LoanTerms } from './components/LoanTerms'

import { ReactComponent as CollageLine } from 'assets/icons/collageLine.svg'
import { ReactComponent as EthIcon } from 'assets/icons/ethIcon.svg'
import openSeaLogo from 'assets/openSeaLogo.png'

interface DescriptionInfoProps extends PropsWithChildren {
  apr: number
  collectionName: string
  duration: number
  id: number
  openSeaUrl: string
  ownerAddress: Address
  principal: number
  projectFloor: number
  repayment: number
  valuation: number
}

export const DescriptionInfo: FC<DescriptionInfoProps> = ({
  apr,
  children,
  collectionName,
  duration,
  id,
  openSeaUrl,
  ownerAddress,
  principal,
  projectFloor,
  repayment,
  valuation,
}) => (
  <div className="h-min rounded-lg bg-third-80 py-8 px-7 text-secondary-10">
    <div className="font-body-4 flex items-center gap-2">
      <CollageLine />
      {collectionName}
    </div>
    <div className="flex items-center justify-between">
      <h4 className="my-[6px]">Collateral #{id}</h4>
      <a href={openSeaUrl} target="_blank" rel="noreferrer">
        <img src={openSeaLogo} alt="OpenSea logo" className="aspect-square h-[22px]" />
      </a>
    </div>
    <div className="font-body-5 text-third-40">Owned by {shortAddress(ownerAddress)}</div>
    <div className="mt-2 mb-6 flex gap-8">
      <div>
        <p className="font-body-5 text-third-40">Project floor</p>
        <div className="flex gap-1">
          <EthIcon height={18} />
          <div>{projectFloor}</div>
        </div>
      </div>
      <div>
        <p className="font-body-5 text-third-40">Valuation</p>
        <div className="flex gap-1">
          <EthIcon height={18} />
          <div>{valuation}</div>
        </div>
      </div>
    </div>
    <LoanTerms principle={principal} repayment={repayment} apr={apr} duration={duration} />
    {children && <div className="mt-8">{children}</div>}
  </div>
)
