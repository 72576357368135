import React, { FC, PropsWithChildren } from 'react'

import * as RadixCollapsible from '@radix-ui/react-collapsible'

import { ReactComponent as ArrowDownBold } from 'assets/icons/arrowDownBold.svg'

interface CollapsibleProps extends PropsWithChildren {
  title: string
}

export const Collapsible: FC<CollapsibleProps> = ({ title, children }) => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <RadixCollapsible.Root
      className="flex flex-col gap-4 rounded-lg border border-third-70 p-4"
      open={open}
      onOpenChange={setOpen}
    >
      <RadixCollapsible.Trigger className="group w-full cursor-pointer" asChild>
        <div className="flex w-full items-center justify-between">
          <h6 className="text-third-10">{title}</h6>
          <ArrowDownBold className="group-data-[state=open]:rotate-180" />
        </div>
      </RadixCollapsible.Trigger>

      <RadixCollapsible.Content className="font-body-3">{children}</RadixCollapsible.Content>
    </RadixCollapsible.Root>
  )
}
