import { forwardRef } from 'react'

import { Collateral } from 'models/Collateral'

import { Button, Link, Tile } from 'components'

import { CollateralsFilters, CollateralsFiltersVal } from './components/CollateralsFilters'

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'

interface CollateralsViewProps {
  collaterals?: Collateral[]
  onFiltersChange: (value: CollateralsFiltersVal) => void
}

export const CollateralsView = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<CollateralsViewProps>
>(({ collaterals, onFiltersChange }, scrollRef) => (
  <div className="container py-20">
    <div className="flex flex-col items-end justify-between gap-6 lg:flex-row">
      <div>
        <h2 className="mb-4 text-third-10">Collaterals</h2>
        <h6 className="text-third-10 lg:w-[600px]">
          Short explanation what user can find here. Short explanation what user can find here.
          Short explanation what user can find here.
        </h6>
      </div>
      <Button icon={<ArrowRight />} onClick={() => {}}>
        Borrow money
      </Button>
    </div>
    <div className="mt-12 mb-[44px]">
      <CollateralsFilters onChange={onFiltersChange} />
    </div>
    <div className="flex flex-wrap justify-center gap-x-12 gap-y-14">
      {collaterals?.map(({ name, id, nfts, apr, currency, principal }, index) => {
        const key = `${id}_${index}`
        return (
          <Link to={`collaterals/${index}`} key={key}>
            <Tile
              title={name}
              type="collateral"
              nfts={nfts}
              apr={apr}
              amount={principal}
              currency={currency}
            />
          </Link>
        )
      })}
    </div>
    {collaterals && (
      <div className="end-of-list invisible relative h-[0px]">
        <div
          ref={scrollRef}
          className="virtual absolute -top-[400px] h-[420px] w-full bg-cyan-200"
        />
      </div>
    )}
  </div>
))
