import {
  CollateralSection,
  FaqSection,
  HeroSection,
  HowSection,
  LoanSection,
  SocialSection,
} from './components'

export const HomeView = () => (
  <>
    <HeroSection />
    <CollateralSection />
    <LoanSection />
    <HowSection />
    <FaqSection />
    <SocialSection />
  </>
)
