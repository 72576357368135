const setItem = (key: string, value: string) => {
  window.localStorage.setItem(key, value)
}

const getItem = (key: string): string | null => window.localStorage.getItem(key)

const removeItem = (key: string) => {
  window.localStorage.removeItem(key)
}

export default {
  setItem,
  getItem,
  removeItem,
}
