import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Address } from 'wagmi'

import { AppDispatch, RootState } from 'store/store'
import { authApi } from 'utils/api'
import { GetAuthTokenVars } from 'utils/api/auth'
import storage from 'utils/storage'

export const getAuthMessage = createAsyncThunk<
  string,
  Address,
  { dispatch: AppDispatch; state: RootState }
>('auth/getAuthMessage', async address => {
  const data = await authApi.getAuthMessage(address)
  return data
})

export const getAuthToken = createAsyncThunk<
  string,
  GetAuthTokenVars,
  { dispatch: AppDispatch; state: RootState }
>('auth/getAuthToken', async variables => {
  const data = await authApi.getAuthToken(variables)
  // Store token for future use
  storage.setItem('token', data)
  return data
})

export const logout = createAsyncThunk<void, void, { dispatch: AppDispatch; state: RootState }>(
  'auth/logout',
  () => {
    // TODO: Do we need a request to BE?
    storage.removeItem('token')
  }
)

export const resetAuth = createAction('auth/resetAuth')
