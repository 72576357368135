import { createSlice } from '@reduxjs/toolkit'

import { RequestStatus, RootState } from 'store/store'

import { getAuthMessage, getAuthToken, resetAuth } from './auth.actions'

type AuthState = {
  message?: string
  getAuthMessageStatus: RequestStatus
  getAuthTokenStatus: RequestStatus
}

const initialState: AuthState = {
  getAuthMessageStatus: 'idle',
  getAuthTokenStatus: 'idle',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAuthMessage.pending, state => {
        state.getAuthMessageStatus = 'pending'
      })
      .addCase(getAuthMessage.fulfilled, (state, action) => {
        state.message = action.payload
        state.getAuthMessageStatus = 'fulfilled'
      })
      .addCase(getAuthMessage.rejected, state => {
        state.getAuthMessageStatus = 'rejected'
      })
      .addCase(getAuthToken.pending, state => {
        state.getAuthTokenStatus = 'pending'
      })
      .addCase(getAuthToken.fulfilled, state => {
        state.getAuthTokenStatus = 'fulfilled'
      })
      .addCase(getAuthToken.rejected, state => {
        state.getAuthTokenStatus = 'rejected'
      })
      .addCase(resetAuth.type, state => {
        state.message = undefined
        state.getAuthMessageStatus = 'idle'
        state.getAuthTokenStatus = 'idle'
      })
  },
})

export const authStateSelector = (state: RootState): AuthState => state.auth

export default authSlice.reducer
