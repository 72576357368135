import { FC } from 'react'

import { ActiveOffer } from 'models/Offer'
import { Table as TableType } from 'models/Table'

import { Collapsible, Table } from 'components'

interface ActiveOffersProps {
  activeOffers: TableType<ActiveOffer>
}

export const ActiveOffers: FC<ActiveOffersProps> = ({ activeOffers }) => {
  const { headers, data } = activeOffers

  return (
    <div>
      <Collapsible title="Active offers">
        <Table columns={headers} data={data} />
      </Collapsible>
    </div>
  )
}
