import { FC } from 'react'

type BadgeProps = {
  value?: string | number | null
}

export const Badge: FC<BadgeProps> = props => {
  const { value } = props

  return (
    <div
      className={`font-overline absolute right-0 top-0 flex min-h-[4px] min-w-[4px] translate-x-1/4 -translate-y-1/2 items-center justify-center rounded-full bg-error-60 text-white ${
        value !== undefined && value !== null ? 'px-1' : ''
      }`}
    >
      {value}
    </div>
  )
}
