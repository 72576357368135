import { FC } from 'react'

import { Currencies } from 'models/Currency'

import { ReactComponent as EthIcon } from 'assets/icons/ethIcon.svg'

interface TileFooterProps {
  amount?: number
  currency?: string
  apr?: number
}

const getCurrencyIcon = (currency: string): JSX.Element => {
  if (currency === Currencies.ETH) return <EthIcon />
  return <> </>
}

export const TileFooter: FC<TileFooterProps> = ({ amount, currency, apr }) => (
  <div className="mx-[9px] mt-4 flex items-end justify-between">
    {amount && currency && (
      <div className="flex flex-col gap-1.5">
        <div className="font-overline text-third-50">Principal</div>
        <div className="font-body-1 flex items-center gap-1.5 font-semibold text-third-20">
          {getCurrencyIcon(currency)}
          {amount}
        </div>
      </div>
    )}
    {apr && (
      <div className="ml-auto flex flex-col items-end gap-1.5">
        <div className="font-overline text-third-50">APR</div>
        <div className="font-body-1 flex items-center gap-1.5 font-semibold text-third-20">
          {apr}%
        </div>
      </div>
    )}
  </div>
)
