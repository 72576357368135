import { FC, useMemo } from 'react'

interface HintProps {
  text: string
  isError?: boolean
  isDisabled?: boolean
}

const getHintClasses = (isError: boolean, isDisabled: boolean): string => {
  let classes = 'body-4 mt-1 min-h-[20px]'

  if (isError) {
    classes += ' text-error-50'
  } else if (isDisabled) {
    classes += ' text-third-50'
  } else {
    classes += ' text-third-40'
  }

  return classes
}

export const Hint: FC<HintProps> = ({ text, isError = false, isDisabled = false }) => {
  const classes = useMemo(() => getHintClasses(isError, isDisabled), [isError, isDisabled])

  return <div className={classes}>{text}</div>
}
