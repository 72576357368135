import { ColumnDefinition } from 'models/Table'

import { TableHeader } from './components/TableHeader'
import { TableRows } from './components/TableRows'

interface TableProps<T> {
  data: Array<T>
  columns: Array<ColumnDefinition<T>>
  headerBoldColsIndexes?: number[]
  bodyBoldColsIndexes?: number[]
}

// <T extends {}> -> https://stackoverflow.com/questions/47848828/how-to-create-stateless-functional-components-that-accept-generics/51585233#51585233
export const Table = <T extends {}>({
  data,
  columns,
  headerBoldColsIndexes,
  bodyBoldColsIndexes,
}: TableProps<T>): JSX.Element => (
  <table className="w-full text-left">
    <TableHeader columns={columns} boldColsIndexes={headerBoldColsIndexes} />
    <TableRows data={data} columns={columns} boldColsIndexes={bodyBoldColsIndexes} />
  </table>
)
