import { FC, PropsWithChildren, useMemo } from 'react'

import { ButtonType } from 'models/Button'
import { Size, Theme } from 'models/Style'

interface ButtonProps extends PropsWithChildren {
  className?: string
  disabled?: boolean
  icon?: React.ReactNode
  onClick: () => void
  size?: Size
  theme?: Theme
  type?: JSX.IntrinsicElements['button']['type']
  variant?: ButtonType
}

const getButtonClasses = (
  size: Size,
  variant: ButtonType,
  theme: Theme,
  hasChildren: boolean,
  hasIcon: boolean
): string => {
  let buttonClasses =
    'flex items-center justify-center transition-all duration-300 rounded-full h-min min-w-max'

  if (size === 'large') {
    buttonClasses += ' p-[15px] font-button-1'
  } else if (size === 'small') {
    buttonClasses += `${hasChildren ? ' px-4' : ' px-1.5'} py-1.5 font-button-2`
  } else {
    // medium
    buttonClasses += `${hasChildren ? ' px-4' : ' px-[11px]'} py-[11px] font-button-1`
  }

  if (variant === 'primary') {
    switch (theme) {
      case 'dark':
        buttonClasses +=
          ' border border-transparent text-third-10 hover:bg-primary-50 hover:border-primary-50' +
          ' disabled:bg-third-70 disabled:border-third-70 disabled:text-third-70'
        break
      case 'light':
        buttonClasses +=
          ' bg-third-10 border border-third-10 text-third-95 hover:bg-third-30' +
          ' hover:border-third-30 disabled:bg-third-30 disabled:border-third-30' +
          ' disabled:text-third-50'
        break
      default:
        buttonClasses +=
          ' bg-primary-50 border border-primary-50 text-third-10 hover:bg-primary-80' +
          ' hover:border-primary-80 disabled:bg-third-30 disabled:border-third-40' +
          ' disabled:text-third-50'
    }
  } else {
    buttonClasses +=
      ' bg-transparent border border-third-10 text-third-10 hover:bg-primary-50' +
      ' hover:border-primary-50 disabled:border-third-70 disabled:text-third-70'
  }

  if (hasIcon && hasChildren) {
    buttonClasses += ' flex gap-2'
  }

  return buttonClasses
}

// button type eslint bug encountered: https://github.com/jsx-eslint/eslint-plugin-react/issues/1555
export const Button: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  icon,
  onClick,
  size = 'medium',
  theme = 'default',
  type = 'button',
  variant = 'primary',
}) => {
  if (!icon && !children) throw new Error('Button component: no icon nor text provided!')
  const buttonClasses = useMemo(
    () => getButtonClasses(size, variant, theme, !!children, !!icon),
    [size, variant]
  )

  return (
    <button
      onClick={onClick}
      type={type === 'submit' ? 'submit' : 'button'}
      disabled={disabled}
      className={`${buttonClasses} ${className}`}
    >
      {icon}
      {children}
    </button>
  )
}
