import { useEffect, useRef, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useIntersectingObserver } from 'hooks/useIntersectingObserver'
import { getCollateralsList, resetCollaterals } from 'store/slices/collaterals/collaterals.actions'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'

import { CollateralsFiltersVal } from './components/CollateralsFilters'
import { CollateralsView } from './CollateralsView'

const COLLATERALS_PER_PAGE = 20

export const CollateralsScreen = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const filters = useRef<CollateralsFiltersVal>()
  const dispatch = useAppDispatch()
  const { collaterals } = useAppSelector(collateralStateSelector)

  const onScroll = () => {
    if (collaterals.length >= (currentPage + 1) * COLLATERALS_PER_PAGE) {
      setCurrentPage(prevState => prevState + 1)
    }
  }

  const handleFiltersChange = (value: CollateralsFiltersVal) => {
    filters.current = value
    setCurrentPage(0)
  }

  useEffect(() => {
    dispatch(
      getCollateralsList({
        limit: COLLATERALS_PER_PAGE,
        offset: COLLATERALS_PER_PAGE * currentPage,
        filters: filters.current,
      })
    )
  }, [currentPage])

  useEffect(
    () => () => {
      dispatch(resetCollaterals())
    },
    []
  )

  const { elementRef: scrollRef } = useIntersectingObserver(onScroll)

  return (
    <CollateralsView
      ref={scrollRef}
      collaterals={collaterals}
      onFiltersChange={handleFiltersChange}
    />
  )
}
