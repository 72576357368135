import { FC, InputHTMLAttributes, useMemo, useState } from 'react'

import * as yup from 'yup'

import { FormInput } from 'models/Form'
import { Position } from 'models/Style'

import { Hint } from '../hint/Hint'
import { Label } from '../label/Label'

type TextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'onChangeText' | 'placeholderTextColor'
> & {
  onValueChange: (input: FormInput) => void
  validationSchema?: yup.BaseSchema
  label?: string
  hint?: string
  icon?: React.ReactNode
  iconPosition?: Position
}

const getTextInputClasses = (
  isDisabled: boolean,
  isValid: boolean,
  iconPosition: 'left' | 'right'
) => {
  let inputContainerClasses =
    'flex items-center overflow-hidden rounded-lg border focus-within:border-primary-60'
  let iconClasses = 'flex p-3 h-full w-12 items-center justify-center'

  if (isDisabled) {
    inputContainerClasses += ' border-neutral-300 bg-neutral-100'
    iconClasses += ' text-neutral-300'
  } else if (!isValid) {
    inputContainerClasses += ' border-red-500'
  } else {
    inputContainerClasses += ' border-third-70'
  }

  if (iconPosition === 'left') {
    inputContainerClasses += ' flex-row-reverse	'
  }

  return { inputContainerClasses, iconClasses }
}

export const TextInput: FC<TextInputProps> = ({
  value,
  onValueChange,
  className,
  validationSchema,
  type,
  label,
  hint = ' ',
  placeholder,
  icon,
  iconPosition = 'right',
  disabled,
}) => {
  const [isValid, setIsValid] = useState<boolean>(true)
  const [hintMessage, setHintMessage] = useState<string>(hint)

  const { inputContainerClasses, iconClasses } = useMemo(
    () => getTextInputClasses(!!disabled, isValid, iconPosition),
    [disabled, isValid]
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value
    onValueChange({ value: newValue, isValid: true })
  }

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newValue = e.target.value
    if (!validationSchema) {
      onValueChange({ value: newValue, isValid: true })
      return
    }
    validationSchema
      .validate(newValue)
      .then(() => {
        onValueChange({ value: newValue, isValid: true })
        setHintMessage(hint)
        setIsValid(true)
      })
      .catch(err => {
        onValueChange({ value: newValue, isValid: false })
        setHintMessage(err.errors[0])
        setIsValid(false)
      })
  }

  return (
    <div className={`flex flex-col ${className}`}>
      {label && <Label text={label} isError={!isValid} />}
      <div className={inputContainerClasses}>
        <input
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          type={type ?? 'text'}
          disabled={disabled}
          placeholder={placeholder ?? 'Input text'}
          className="h-full w-full bg-transparent p-3 text-third-40 outline-0 placeholder:text-neutral-400 disabled:bg-neutral-100 disabled:placeholder:text-neutral-300"
        />
        {icon && <div className={iconClasses}>{icon}</div>}
      </div>
      <Hint text={hintMessage ?? hint} isError={!isValid} isDisabled={disabled} />
    </div>
  )
}
