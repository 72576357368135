import axios from 'axios'

import storage from 'utils/storage'

const API = axios.create()
API.defaults.headers.common['Content-Type'] = 'application/json'

API.interceptors.request.use(config => {
  const token = storage.getItem('token')
  if (token) {
    // TODO: check token expiration date and refresh token before making appropriate request
    config.headers = config.headers ?? {}
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default API
