import { useState } from 'react'

import * as yup from 'yup'

import { Currencies } from 'models/Currency'
import { FormInput } from 'models/Form'

import { Accordion, BannerHomeTop, Button, Collapsible, Dialog, Table, Tile } from 'components'
import { TextInput } from 'components/form/text-input/TextInput'

import { accordionElements, columns, data, nfts, validationSchema } from './helper'

import { ReactComponent as Funnel } from 'assets/icons/funnel.svg'

export const DemoView = () => {
  const [firstInputValue, setFirstInputValue] = useState('')
  const [secondInputValue, setSecondInputValue] = useState('')
  const [thirdInputValue, setThirdInputValue] = useState('')
  const [isDialogVisible, setIsDialogVisible] = useState(false)

  return (
    <div className="container flex flex-col items-center justify-center gap-8 py-8">
      <BannerHomeTop />
      <Collapsible title="This is collapsible">
        <div className="flex flex-col items-center justify-center">
          <Table columns={columns} data={data} />
        </div>
      </Collapsible>
      <div className="flex flex-col items-start gap-8 md:flex-row">
        <Tile nfts={nfts.slice(1, 2)} title="This is tile" type="collateral" />
        <Tile
          nfts={nfts}
          type="loan"
          title="This is tile"
          subtitle="This is optional subtitle"
          userType="borrower"
          status="active"
          apr={10}
          amount={123}
          currency={Currencies.ETH}
        />
      </div>

      <div className="flex flex-col flex-wrap gap-8">
        <TextInput
          value={firstInputValue}
          onValueChange={(newValue: FormInput) => setFirstInputValue(newValue.value)}
          label="This is text input"
          hint="No validation here"
        />
        <TextInput
          value={secondInputValue}
          onValueChange={(newValue: FormInput) => setSecondInputValue(newValue.value)}
          label="This is text input with validation"
          validationSchema={yup.reach(validationSchema, 'exampleInput')}
        />
        <TextInput
          value={thirdInputValue ?? 0}
          onValueChange={(newValue: FormInput) => setThirdInputValue(newValue.value)}
          type="number"
          label="Numeric input with %"
          hint="Value between 0-100"
          icon="%"
          validationSchema={yup.reach(validationSchema, 'numericInput')}
        />
      </div>
      {isDialogVisible && (
        <Dialog
          title="This is dialog"
          onCancel={() => setIsDialogVisible(false)}
          onAccept={() => setIsDialogVisible(false)}
        >
          <div className="mb-4 font-bold">This is example of the dialog content.</div>
          <div>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit, exercitationem doloribus
            ad excepturi, mollitia voluptates ea veritatis, eaque corrupti corporis vel nisi
            laudantium accusantium. Architecto hic magnam amet repellat dolores.
          </div>
        </Dialog>
      )}
      <div className="flex flex-col gap-2">
        <Button onClick={() => setIsDialogVisible(true)}>Show dialog</Button>
        <Button onClick={() => {}} size="large">
          Large
        </Button>
        <Button onClick={() => {}}>Primary default</Button>
        <Button onClick={() => {}} size="small">
          Small
        </Button>

        <div className="flex gap-2">
          <Button onClick={() => {}} size="large" icon={<Funnel />} />
          <Button onClick={() => {}} size="medium" icon={<Funnel />} />
          <Button onClick={() => {}} size="small" icon={<Funnel />} />
        </div>

        <Button onClick={() => {}} variant="primary" theme="light">
          Primary light
        </Button>
        <Button onClick={() => {}} variant="primary" theme="dark">
          Primary dark
        </Button>
        <Button onClick={() => {}} variant="secondary">
          Secondary
        </Button>
      </div>
      <Accordion elements={accordionElements} />
    </div>
  )
}
