import { ColumnDefinition } from 'models/Table'

type TableHeaderProps<T> = {
  columns: Array<ColumnDefinition<T>>
  boldColsIndexes?: number[]
}

// <T extends {}> -> https://stackoverflow.com/questions/47848828/how-to-create-stateless-functional-components-that-accept-generics/51585233#51585233
export const TableHeader = <T extends {}>({
  columns,
  boldColsIndexes,
}: TableHeaderProps<T>): JSX.Element => {
  const headers = columns.map((column, index) => {
    const headCellKey = `headCell-${index}`

    return (
      <th
        key={headCellKey}
        className={`${
          boldColsIndexes?.includes(index) ? 'font-semibold' : 'font-normal'
        } py-3 text-third-50 ${
          columns.length < 5 && index === 0 ? 'font-body-3 w-[50%]' : 'font-body-4'
        }`}
      >
        {column.header}
      </th>
    )
  })

  return (
    <thead className="border-b border-third-70">
      <tr>{headers}</tr>
    </thead>
  )
}
