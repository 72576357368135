import { FC, PropsWithChildren } from 'react'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import { Hint } from 'components/form/hint/Hint'
import { Label } from 'components/form/label/Label'

import { ReactComponent as ChevronDown } from 'assets/icons/chevronDown.svg'

interface DropdownProps extends PropsWithChildren {
  title: string
  label?: string
  hint?: string
}

export const Dropdown: FC<DropdownProps> = ({ title, label, hint = ' ', children }) => (
  <DropdownMenu.Root modal={false}>
    <div>
      {label && <Label text={label} classNames="text-third-10	font-body-3" />}
      <DropdownMenu.Trigger className="group font-button-1 grid w-full grid-cols-filterTrigger items-center overflow-hidden rounded-lg border border-third-70 py-3 px-4 text-third-10 data-[state=open]:border-primary-50">
        <p className="truncate text-left">{title}</p>
        <ChevronDown className="group-data-[state=open]:rotate-180" />
      </DropdownMenu.Trigger>
      <Hint text={hint} />
    </div>
    <DropdownMenu.Portal>
      <DropdownMenu.Content
        className="min-w-[163px] rounded-lg border border-third-70 bg-dropdown p-6 text-third-10"
        align="start"
        sideOffset={6}
        collisionPadding={10}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Portal>
  </DropdownMenu.Root>
)
