import { FC } from 'react'

interface LoanTermsProps {
  principle: number
  repayment: number
  apr: number
  duration: number
}

const loanTermBoxClasses = 'flex flex-col gap-2 rounded-md bg-third-90 p-3'
const textHeadingClasses = 'font-overline text-third-50'
const textValueClasses = 'font-body-3 font-semibold'

export const LoanTerms: FC<LoanTermsProps> = ({ principle, repayment, apr, duration }) => (
  <div>
    <h5>Loan Terms</h5>
    <div className="mt-4 grid grid-cols-2 gap-x-6 gap-y-4 text-third-10">
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>PRINCIPLE</div>
        <div className={textValueClasses}>{principle}</div>
      </div>
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>REPAYMENT</div>
        <div className={textValueClasses}>{repayment}</div>
      </div>
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>APR</div>
        <div className={textValueClasses}>{apr}%</div>
      </div>
      <div className={loanTermBoxClasses}>
        <div className={textHeadingClasses}>DURATION</div>
        <div className={textValueClasses}>{duration} days</div>
      </div>
    </div>
  </div>
)
