import { FC } from 'react'

import { CollateralDetails } from 'models/Collateral'

import { Button, ButtonBack } from 'components'
import { DescriptionInfo } from 'components/description-info'
import { TileImages } from 'components/tile/components/TileImages'

import { ActiveOffers } from './components/ActiveOffers'
import { Activities } from './components/Activities'
import { BorrowerInformation } from './components/BorrowerInformation'

interface CollateralDetailsProps {
  collateralDetails?: CollateralDetails
}

export const CollateralDetailView: FC<CollateralDetailsProps> = ({ collateralDetails }) => {
  if (!collateralDetails) return <> </>
  const {
    activeOffers,
    activities,
    apr,
    borrower,
    borrowerInformationsBorrow: borrowTable,
    borrowerInformationsLend: lendTable,
    collectionName,
    duration,
    id,
    nfts,
    openSeaUrl,
    principal,
    projectFloor,
    repayment,
    valuation,
  } = collateralDetails

  return (
    <div className="container lg:max-w-[996px]">
      <div className="mt-10 mb-7">
        <ButtonBack text="Back to marketplace" path="/collaterals" />
      </div>
      <div className="grid gap-x-4 gap-y-8 lg:grid-cols-detailsContainer">
        <div>
          <TileImages nfts={nfts} size="large" />
        </div>
        <DescriptionInfo
          apr={apr}
          collectionName={collectionName}
          duration={duration}
          id={id}
          openSeaUrl={openSeaUrl}
          ownerAddress={borrower}
          principal={principal}
          projectFloor={projectFloor}
          repayment={repayment}
          valuation={valuation}
        >
          <div className="flex flex-col gap-4">
            <Button onClick={() => {}} className="w-full">
              Deal now
            </Button>
            <Button onClick={() => {}} className="w-full" variant="secondary">
              Make an offer
            </Button>
          </div>
        </DescriptionInfo>
        <div className="mb-12 flex flex-col gap-8">
          <Activities activities={activities} />
          <BorrowerInformation borrowTable={borrowTable} lendTable={lendTable} />
          <ActiveOffers activeOffers={activeOffers} />
        </div>
      </div>
    </div>
  )
}
