import { Provider } from 'react-redux'
import { WagmiConfig } from 'wagmi'

import { client } from 'hooks/useWallet'
import { store } from 'store/store'

import AppContainer from './AppContainer'

const App = () => (
  <WagmiConfig client={client}>
    <Provider store={store}>
      <AppContainer />
    </Provider>
  </WagmiConfig>
)

export default App
