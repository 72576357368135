import { Activity } from 'models/Activity'
import { BorrowerInformation } from 'models/Borrower'
import { Collateral, CollateralDetails } from 'models/Collateral'
import { ActiveOffer } from 'models/Offer'
import { Table } from 'models/Table'

export const mockedCollateral: Collateral = {
  name: 'collection1',
  id: 123,
  borrower: '0x816BF554FA387DeFFFB7B3F6bd9A66537980cD88',
  createdAt: '2022-12-21T12:26:07.997143Z',
  principal: 200,
  apr: 5,
  duration: 90,
  currency: 'ETH',
  nfts: [
    {
      tokenId: '1',
      name: 'token1',
      thumbnail:
        'https://i.seadn.io/gae/phV8vtTRtt_Sj_54f9mu6x6aYVVKsU8G5O3Y2Ah05Kw-U8je7qcpdsomIAbp7XD7_dAI68pfzRst8myVPzt1r8ikCtnf8zAEFKkDSCc?auto=format&w=1000',
      address: '0x94672a68E135df6E84fbF35cbA32E973c8F61476',
    },
    {
      tokenId: '2',
      name: 'token2',
      thumbnail:
        'https://i.seadn.io/gae/NWXBYbzZwot_CQVxlJUdvWtmiHcq3rxYpv-hV7Uq73_-yy_LihxlcW5tx8LTiwi3qnbADKjICjdsceKNb13WZQJsCir1sao8xLf8pQ?auto=format&w=1000',
      address: '0x94672a68E135df6E84fbF35cbA32E973c8F61477',
    },
  ],
}

export const mockedCollateralsList: Collateral[] = Array(20).fill(mockedCollateral)

export const activities: Table<Activity> = {
  headers: [
    {
      key: 'activity',
      header: '',
    },
    {
      key: 'eth',
      header: 'ETH',
    },
    {
      key: 'usdt',
      header: 'USDT',
    },
    {
      key: 'usdc',
      header: 'USDC',
    },
  ],
  data: [
    {
      activity: 'Average borrowed',
      eth: 2.78,
      usdt: 3.56,
      usdc: 5.67,
    },
    {
      activity: 'Average APR (%)',
      eth: 15,
      usdt: 12,
      usdc: 25,
    },
    {
      activity: 'Average duration (days)',
      eth: 33,
      usdt: 40,
      usdc: 60,
    },
  ],
}

export const activeOffers: Table<ActiveOffer> = {
  headers: [
    { key: 'id', header: 'ID' },
    { key: 'loanPrincipal', header: 'Loan Principal' },
    { key: 'repayment', header: 'Repayment' },
    { key: 'apr', header: 'APR %' },
    { key: 'duration', header: 'Duration (days)' },
  ],
  data: [
    { id: 'fkwg2tesjtk2t', loanPrincipal: '1.3 ETH', repayment: '1.5 ETH', apr: 10, duration: 50 },
    { id: '13rkqfhbeiws', loanPrincipal: '1.3 ETH', repayment: '1.5 ETH', apr: 10, duration: 50 },
  ],
}

export const borrowerInformationsBorrow: Table<BorrowerInformation> = {
  headers: [
    {
      key: 'stat',
      header: 'Borrow',
    },
    {
      key: 'eth',
      header: 'ETH',
    },
    {
      key: 'usdt',
      header: 'USDT',
    },
    {
      key: 'usdc',
      header: 'USDC',
    },
  ],
  data: [
    {
      stat: 'Total borrowed',
      eth: 20,
      usdc: 1500,
      usdt: 2000,
    },
    {
      stat: 'Borrowed times',
      eth: 5,
      usdc: 4,
      usdt: 1,
    },
    {
      stat: 'Repaid times',
      eth: 4,
      usdc: 4,
      usdt: 0,
    },
    {
      stat: 'Average borrowed',
      eth: 4,
      usdc: 500,
      usdt: 1500,
    },
    {
      stat: 'Default rate (%)',
      eth: 20,
      usdc: 0,
      usdt: 100,
    },
  ],
}

export const borrowerInformationsLend: Table<BorrowerInformation> = {
  headers: [
    {
      key: 'stat',
      header: 'Lend',
    },
    {
      key: 'eth',
      header: 'ETH',
    },
    {
      key: 'usdt',
      header: 'USDT',
    },
    {
      key: 'usdc',
      header: 'USDC',
    },
  ],
  data: [
    {
      stat: 'Total lend',
      eth: 15.25,
      usdc: 22000,
      usdt: 10500,
    },
    {
      stat: 'Lent times',
      eth: 5,
      usdc: 3,
      usdt: 1,
    },
    {
      stat: 'Average lent',
      eth: 3.05,
      usdc: 7333.33,
      usdt: 10.5,
    },
  ],
}

export const mockedCollateralDetails: CollateralDetails = {
  ...mockedCollateral,
  activeOffers,
  activities,
  borrowerInformationsBorrow,
  borrowerInformationsLend,
  collectionName: 'Valhalla',
  openSeaUrl: 'https://opensea.io/',
  projectFloor: 1.33,
  repayment: 1.3,
  valuation: 1.34,
}
