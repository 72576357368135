import { FC, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { Currencies } from 'models/Currency'

import { Dropdown } from 'components/form/dropdown'

import { ReactComponent as Check } from 'assets/icons/check.svg'

interface FilterCurrencyProps {
  onChange: (value: Currencies) => void
  defaultValue: Currencies
}

export const FilterCurrency: FC<FilterCurrencyProps> = ({ onChange, defaultValue }) => {
  const [filterValue, setFilterValue] = useState(defaultValue)

  const onSelect = (currency: Currencies) => {
    onChange(currency)
    setFilterValue(currency)
  }

  return (
    <Dropdown title={filterValue} label="Currency">
      <div className="flex flex-col">
        {Object.values(Currencies).map(currency => (
          <DropdownMenuItem key={currency} onClick={() => onSelect(currency)}>
            <div className="group flex cursor-pointer items-center justify-between">
              <div className="font-body-3 py-2.5 px-5">{currency}</div>
              <Check className={`${filterValue !== currency && 'invisible'} group-hover:visible`} />
            </div>
          </DropdownMenuItem>
        ))}
      </div>
    </Dropdown>
  )
}
