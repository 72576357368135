import { FC, useMemo, useState } from 'react'

import { useWallet } from 'hooks/useWallet'
import { shortAddress } from 'utils/address'

import { Button } from 'components'

import { SideNav } from './SideNav'

import { ReactComponent as EthIcon } from 'assets/icons/ethIcon.svg'

type NavigationProfileProps = {
  className?: string
}

export const NavigationProfile: FC<NavigationProfileProps> = props => {
  const { className } = props

  const [sideVisible, setSideVisible] = useState(false)
  const { address, chain, connectWallet, disconnectWallet, isLoading, status } = useWallet()

  const addressShort = useMemo(() => shortAddress(address), [address])

  const showSideNav = () => setSideVisible(true)
  const hideSideNav = () => setSideVisible(false)

  const handleDisconnect = () => {
    disconnectWallet()
    hideSideNav()
  }

  const handleConnect = () => {
    connectWallet()
    hideSideNav()
  }

  return (
    <div className={className}>
      {status === 'connected' && !isLoading && (
        <button
          type="button"
          onClick={showSideNav}
          className="font-body-3 flex items-center justify-between gap-2 rounded-xl border border-third-20 p-2 text-third-20"
        >
          <EthIcon />
          {addressShort}
        </button>
      )}

      {(status === 'disconnected' || isLoading) && (
        <Button
          onClick={showSideNav}
          variant="secondary"
          className={className}
          disabled={isLoading}
        >
          {isLoading ? '...' : 'Connect wallet'}
        </Button>
      )}

      <SideNav
        address={address}
        addressShort={addressShort}
        chain={chain}
        isVisible={sideVisible}
        onConnect={handleConnect}
        onDisconnect={handleDisconnect}
        onHide={hideSideNav}
        status={status}
      />
    </div>
  )
}
