import { FC } from 'react'

import { useNavigate } from 'react-router-dom'

import { ReactComponent as ArrowDownBold } from 'assets/icons/arrowDownBold.svg'

interface ButtonBackProps {
  text: string
  path: string
}

export const ButtonBack: FC<ButtonBackProps> = ({ text, path }) => {
  const navigate = useNavigate()
  const onButtonClick = () => navigate(path)

  return (
    <button
      type="button"
      onClick={onButtonClick}
      className="font-button-1 flex items-center gap-1 text-third-50"
    >
      <ArrowDownBold className="w-[10.5px] rotate-90 fill-third-50" />
      {text}
    </button>
  )
}
