import { FC, useState } from 'react'

import { NFT } from 'models/Collateral'
import { Size } from 'models/Style'

interface TileImagesProps {
  nfts: NFT[]
  size?: Size
}

export const TileImages: FC<TileImagesProps> = ({ nfts, size }) => {
  const isSingleImage = nfts.length === 1
  const [highlightedImageIndex, setHighlightedImageIndex] = useState(0)

  if (isSingleImage) {
    return <img src={nfts[0].thumbnail} alt="desc" className="h-full w-full rounded object-cover" />
  }

  return (
    <div
      className={`grid h-full w-full grid-cols-tile justify-center ${
        size === 'large' ? 'gap-2' : 'gap-1'
      }`}
    >
      <div className="scrollbar-hide flex flex-col gap-2 overflow-auto">
        {nfts.map(({ thumbnail }, index) => {
          const key = `${thumbnail}${index}`
          return (
            <img
              key={key}
              src={thumbnail}
              onMouseEnter={() => setHighlightedImageIndex(index)}
              alt="desc"
              className={`${
                size === 'large' ? 'md:h-[100px] md:w-[100px]' : ''
              } h-[55px] w-[55px] rounded object-cover`}
            />
          )
        })}
      </div>
      <img
        src={nfts[highlightedImageIndex].thumbnail}
        alt="desc"
        className="h-full w-full rounded object-cover"
      />
    </div>
  )
}
