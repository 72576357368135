import { createSlice } from '@reduxjs/toolkit'

import { Collateral, CollateralDetails } from '../../../models/Collateral'
import type { RequestStatus, RootState } from '../../store'
import { getCollateralDetails, getCollateralsList, resetCollaterals } from './collaterals.actions'

export type CollateralState = {
  collaterals: Collateral[]
  collateralDetails?: CollateralDetails
  getCollateralsListStatus: RequestStatus
  getCollateralDetailsStatus: RequestStatus
}

const initialState: CollateralState = {
  collaterals: [],
  collateralDetails: undefined,
  getCollateralsListStatus: 'idle',
  getCollateralDetailsStatus: 'idle',
}

export const collateralSlice = createSlice({
  name: 'collaterals',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCollateralsList.pending, state => {
        state.getCollateralsListStatus = 'pending'
      })
      .addCase(getCollateralsList.fulfilled, (state, action) => {
        state.collaterals = [...state.collaterals, ...action.payload]
        state.getCollateralsListStatus = 'fulfilled'
      })
      .addCase(getCollateralsList.rejected, state => {
        state.getCollateralsListStatus = 'rejected'
      })
      .addCase(getCollateralDetails.pending, state => {
        state.getCollateralsListStatus = 'pending'
      })
      .addCase(getCollateralDetails.fulfilled, (state, action) => {
        state.collateralDetails = action.payload
        state.getCollateralsListStatus = 'fulfilled'
      })
      .addCase(getCollateralDetails.rejected, state => {
        state.getCollateralsListStatus = 'rejected'
      })
      .addCase(resetCollaterals.type, state => {
        state.collaterals = []
      })
  },
})

export const collateralStateSelector = (state: RootState): CollateralState => state.collaterals

export default collateralSlice.reducer
