import { FC } from 'react'

import { Status } from 'models/Style'
import { UserType } from 'models/User'

import { Chip } from 'components/chip'

interface TileHeaderProps {
  title?: string
  subtitle?: string
  type?: UserType
  status?: Status
}

export const TileHeader: FC<TileHeaderProps> = ({ title, subtitle, type, status }) => (
  <div className="mb-4 flex items-start justify-between gap-3">
    <div className="flex shrink flex-col gap-1 overflow-hidden">
      {title && <div className="font-header-h6 truncate text-third-20">{title}</div>}
      {subtitle && <div className="font-overline truncate text-secondary-20">{subtitle}</div>}
    </div>
    <div className="flex flex-col items-end gap-3">
      {status && <Chip type={status} />}
      {type && <Chip type={type} />}
    </div>
  </div>
)
