import { useLocation } from 'react-router-dom'

import { ProfileOffersView } from './ProfileOffersView'

export const ProfileOffersScreen = () => {
  const location = useLocation()

  const path = location.pathname.replace('/profile/', '')

  // TODO: Request for offers depending on path value. Data structure will be the same so we can
  //  use same view and handle same events

  return (
    <>
      Location: {path}
      <ProfileOffersView />
    </>
  )
}
