import { FC } from 'react'

import { Status } from 'models/Style'
import { UserType } from 'models/User'

interface ChipProps {
  type: Status | UserType
  label?: string
}

export const Chip: FC<ChipProps> = ({ type, label }) => {
  const getBgColor = (): string => {
    if (type === 'active') return 'bg-green-500 text-white'
    if (type === 'closed') return 'bg-neutral-500 text-white'
    if (type === 'admin') return 'bg-neutral-500 text-white'
    if (type === 'borrower') return 'bg-neutral-500 text-white'
    if (type === 'lender') return 'bg-neutral-500 text-white'
    return ''
  }

  return (
    <div className={`font-overline rounded-full px-2 py-0.5 ${getBgColor()}`}>
      {label ?? type.toUpperCase()}
    </div>
  )
}
