import { ReactComponent as DiscordIcon } from 'assets/icons/discord.svg'

export const SocialSection = () => (
  <div className="container py-14 sm:py-[216px]">
    <div className="flex flex-col items-center rounded-[20px] border-2 border-secondary-20 px-4 pt-12 pb-12 sm:pt-[75px] sm:pb-[66px]">
      <h2 className="font-header-1 mb-9 max-w-[410px] text-center">
        Join our 1000+ users community
      </h2>
      <h6 className="mb-9 max-w-[396px] text-center">
        If you still haven’t found what are you looking for reach us on XYZ.
      </h6>
      <div className="flex gap-7">
        <a href="https://discord.com" target="_blank" rel="noreferrer">
          <DiscordIcon className="fill-primary-10 transition-transform duration-300 hover:scale-90" />
        </a>
        <a href="https://twitter.com" target="_blank" rel="noreferrer">
          <DiscordIcon className="fill-primary-10 transition-transform duration-300 hover:scale-90" />
        </a>
        <a href="https://metamask.io/" target="_blank" rel="noreferrer">
          <DiscordIcon className="fill-primary-10 transition-transform duration-300 hover:scale-90" />
        </a>
      </div>
    </div>
  </div>
)
