import { FC } from 'react'

import { useWallet } from 'hooks/useWallet'

import { Badge } from 'components'

import { ReactComponent as BellIcon } from 'assets/icons/bell.svg'

type NavigationNotificationProps = {
  className?: string | undefined
}

export const NavigationNotification: FC<NavigationNotificationProps> = props => {
  const { className } = props

  const { status } = useWallet()

  if (status !== 'connected') return <div className={className} />

  return (
    <div className={`${className} relative w-fit`}>
      <BellIcon className="h-6 w-6 stroke-third-10" />
      <Badge value={6} />
    </div>
  )
}
