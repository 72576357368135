import { useEffect } from 'react'

import { useParams } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { getCollateralDetails } from 'store/slices/collaterals/collaterals.actions'
import { collateralStateSelector } from 'store/slices/collaterals/collaterals.slice'

import { CollateralDetailView } from './CollateralDetailView'

export const CollateralDetailScreen = () => {
  const dispatch = useAppDispatch()
  const { collateralDetails } = useAppSelector(collateralStateSelector)
  const { id } = useParams()

  useEffect(() => {
    if (!id) return
    dispatch(getCollateralDetails({ id }))
  }, [id])

  return <CollateralDetailView collateralDetails={collateralDetails} />
}
