import { useNavigate } from 'react-router-dom'

import { Button } from 'components'

import { SCREENS } from '../../../utils/constants'

import { ReactComponent as ArrowRight } from 'assets/icons/arrowRight.svg'
import homeHero from 'assets/images/homeHero.jpg'
import homeHeroSm from 'assets/images/homeHeroSm.jpg'

export const HeroSection = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className="container flex flex-col items-center pt-8 sm:pt-28 sm:pb-0">
        <h1 className="mb-6 max-w-[620px] text-center">Need some money for investment?</h1>
        <h5 className="mb-10 max-w-[550px] text-center">
          If you still haven’t found what are you looking for reach us on XYZ.
        </h5>
        <div className="flex flex-col gap-4 sm:flex-row sm:gap-8">
          <Button onClick={() => navigate('/loans')} variant="secondary">
            Earn on lending money
          </Button>
          <Button onClick={() => navigate('/collaterals')} icon={<ArrowRight />}>
            Get money for investment
          </Button>
        </div>
      </div>
      <picture className="w-full">
        <source media={`(max-width: ${SCREENS.maxSx})`} srcSet={homeHeroSm} type="image/jpg" />
        <source media={`(min-width: ${SCREENS.sm})`} srcSet={homeHero} type="image/jpg" />
        <img src={homeHero} alt="Zexus" className="w-full" />
      </picture>
    </>
  )
}
