type ApiUrls = {
  ZEXUS_API: string | undefined
}

export const API_URLS: ApiUrls = {
  ZEXUS_API: process.env.REACT_APP_ZEXUS_API,
}

export const API_KEYS = {
  ALCHEMY: process.env.ALCHEMY_KEY,
}

// In theory, you can import Tailwind config through resolveConfig but this makes the same thing
// that Tailwind is doing for itself (double work) so here we store same values for media
// queries used inside jsx files (i.e. for picture source breakpoints.
// If we will use more data from config then its worth to consider importing config from Tailwind.
export const SCREENS = {
  maxSx: '639px',
  sm: '640px',
  // => @media (min-width: 640px) { ... }
  md: '768px',
  // => @media (min-width: 768px) { ... }
  lg: '1024px',
  // => @media (min-width: 1024px) { ... }
  xl: '1280px',
  // => @media (min-width: 1280px) { ... }
  '2xl': '1536px',
  // => @media (min-width: 1536px) { ... }
}
