import { verifyMessage } from 'ethers/lib/utils'
import { Address, useSignMessage as useSignMessageHook } from 'wagmi'

import { parseMessageError } from 'utils/message'

export const useSignMessage = (address: Address | undefined) => {
  const { data, signMessage, variables, isSuccess, isLoading, error, reset } = useSignMessageHook()

  return {
    signature: data,
    signMessage,
    isLoading,
    isSuccess,
    isVerified:
      address && variables && data ? verifyMessage(variables.message, data) === address : false,
    error: parseMessageError(error),
    reset,
  }
}
