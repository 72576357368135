import { FC, useEffect, useState } from 'react'

import * as Portal from '@radix-ui/react-portal'

import { Currencies } from 'models/Currency'
import { MinMax } from 'models/Form'
import { SortItem } from 'models/Sort'

import {
  Button,
  FilterApr,
  FilterCurrency,
  FilterDuration,
  FilterPrincipal,
  Sort,
} from 'components'
import { TextInput } from 'components/form/text-input/TextInput'

import { ReactComponent as Close } from 'assets/icons/close.svg'
import { ReactComponent as Funnel } from 'assets/icons/funnel.svg'
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifyingGlass.svg'

export type CollateralsFiltersVal = {
  apr: MinMax
  currency: Currencies
  principal: MinMax
  duration: MinMax
  sort: string
}

interface FiltersProps {
  onChange: (value: CollateralsFiltersVal) => void
}

const DEFAULT_APR = { min: 1, max: 156 }
const DEFAULT_PRINCIPAL = { min: 0.01, max: 1 }
const DEFAULT_DURATION = { min: 1, max: 365 }
const DEFAULT_CURRENCY = Currencies.ALL
const DEFAULT_SORT_METHOD = 'asc_amount'
const sortItems: Array<SortItem> = ['newest', 'oldest', 'desc_amount', 'asc_amount']

export const CollateralsFilters: FC<FiltersProps> = ({ onChange }) => {
  const [searchValue, setSearchValue] = useState('')
  const [apr, setApr] = useState<MinMax>(DEFAULT_APR)
  const [currency, setCurrency] = useState(Currencies.ALL)
  const [principal, setPrincipal] = useState<MinMax>(DEFAULT_PRINCIPAL)
  const [duration, setDuration] = useState<MinMax>(DEFAULT_DURATION)
  const [sortMethod, setSortMethod] = useState<string>(DEFAULT_SORT_METHOD)
  const [areFiltersMobileVisible, setAreFiltersMobileVisible] = useState(false)

  useEffect(() => {
    onChange({ apr, currency, principal, duration, sort: sortMethod })
  }, [apr])

  return (
    <>
      <div className="hidden w-full items-end gap-4 md:flex">
        <TextInput
          className="h-full grow"
          value={searchValue}
          onValueChange={newValue => setSearchValue(newValue.value)}
          placeholder="Search"
          icon={<MagnifyingGlass />}
          iconPosition="left"
        />
        <div className="w-[158px]">
          <FilterApr onChange={setApr} defaultValue={DEFAULT_APR} />
        </div>
        <div className="w-[110px]">
          <FilterCurrency onChange={setCurrency} defaultValue={DEFAULT_CURRENCY} />
        </div>
        <div className="w-[158px]">
          <FilterPrincipal onChange={setPrincipal} defaultValue={DEFAULT_PRINCIPAL} />
        </div>
        <div className="w-[158px]">
          <FilterDuration onChange={setDuration} defaultValue={DEFAULT_DURATION} />
        </div>
        <div className="ml-[18px] w-[158px]">
          {sortItems && (
            <Sort items={sortItems} onChange={setSortMethod} defaultValue={DEFAULT_SORT_METHOD} />
          )}
        </div>
      </div>

      <div className="flex w-full items-center gap-4 md:hidden">
        <TextInput
          className="h-full grow"
          value={searchValue}
          onValueChange={newValue => setSearchValue(newValue.value)}
          placeholder="Search"
          icon={<MagnifyingGlass />}
          iconPosition="left"
        />
        <Button
          onClick={() => {
            setAreFiltersMobileVisible(true)
          }}
          icon={<Funnel />}
        />

        <Portal.Root
          className={`fixed top-0 left-0 flex ${
            areFiltersMobileVisible ? 't-0 h-screen' : '-top-full h-0'
          } w-screen flex-col items-center justify-center gap-4 bg-third-95 transition-all`}
        >
          <Button
            theme="dark"
            className="absolute right-10 top-5"
            onClick={() => setAreFiltersMobileVisible(false)}
            icon={<Close />}
          />

          <div className="w-10/12 max-w-sm">
            <FilterApr onChange={setApr} defaultValue={DEFAULT_APR} />
          </div>
          <div className="w-10/12 max-w-sm">
            <FilterCurrency onChange={setCurrency} defaultValue={DEFAULT_CURRENCY} />
          </div>
          <div className="w-10/12 max-w-sm">
            <FilterPrincipal onChange={setPrincipal} defaultValue={DEFAULT_PRINCIPAL} />
          </div>
          <div className="w-10/12 max-w-sm">
            <FilterDuration onChange={setDuration} defaultValue={DEFAULT_DURATION} />
          </div>
          <div className="w-10/12 max-w-sm">
            {sortItems && (
              <Sort items={sortItems} onChange={setSortMethod} defaultValue={DEFAULT_SORT_METHOD} />
            )}
          </div>
        </Portal.Root>
      </div>
    </>
  )
}
