import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { Collateral, CollateralDetails } from 'models/Collateral'
import type { AppDispatch, RootState } from 'store/store'
import { collateralsApi } from 'utils/api'
import { GetCollateralDetailsVars, GetCollateralsListVars } from 'utils/api/collaterals'

export const getCollateralsList = createAsyncThunk<
  Collateral[],
  GetCollateralsListVars,
  { dispatch: AppDispatch; state: RootState }
>('collaterals/getCollateralsList', async variables => {
  const data = await collateralsApi.getCollateralsList(variables)
  return data
})

export const getCollateralDetails = createAsyncThunk<
  CollateralDetails,
  GetCollateralDetailsVars,
  { dispatch: AppDispatch; state: RootState }
>('collaterals/getCollateralDetails', async variables => {
  const data = await collateralsApi.getCollateralDetails(variables)
  return data
})

export const resetCollaterals = createAction('collaterals/resetCollaterals')
