import { useEffect } from 'react'

import {
  configureChains,
  Connector,
  createClient,
  goerli,
  mainnet,
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
} from 'wagmi'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'

import { useAppDispatch, useAppSelector } from 'hooks/store.hooks'
import { useSignMessage } from 'hooks/useSignMessage'
import { getAuthMessage, getAuthToken, logout, resetAuth } from 'store/slices/auth/auth.actions'
import { authStateSelector } from 'store/slices/auth/auth.slice'
import { API_KEYS } from 'utils/constants'

const { chains, provider } = configureChains(
  [mainnet, goerli],
  [...(API_KEYS.ALCHEMY ? [alchemyProvider({ apiKey: API_KEYS.ALCHEMY })] : []), publicProvider()]
)

export const client = createClient({
  autoConnect: true,
  provider,
  connectors: [new MetaMaskConnector({ chains })],
})

type OnConnectVars = {
  connector?: Connector
  isReconnected: boolean
}

export const useWallet = () => {
  const { message, getAuthTokenStatus } = useAppSelector(authStateSelector)
  const dispatch = useAppDispatch()

  const onConnect = ({ connector, isReconnected }: OnConnectVars) => {
    if (connector && isReconnected) {
      // console.log('-> RECONNECTED', { name: connector?.name, isReconnected })
      // TODO: React when reconnected (if after reconnect account change is also triggered we can
      //  skip this because system will ask for new token anyway)
    }
  }

  const onDisconnect = () => {
    dispatch(logout())
  }

  const { connect, connectors } = useConnect()
  const { disconnect } = useDisconnect()
  const { address, status, connector } = useAccount({ onConnect, onDisconnect })
  const { chain } = useNetwork()

  const {
    isLoading: isMessageLoading,
    signature,
    signMessage,
    isVerified,
    reset: resetMessage,
  } = useSignMessage(address)

  useEffect(() => {
    if (chain) {
      // TODO: React to network change
      // console.log('-> NETWORK', chain.name)
    }
  }, [chain?.id])

  useEffect(() => {
    if (address) {
      // Account changed (wallet address) so we need to get a new token from BE
      // Get message to be signed
      dispatch(getAuthMessage(address))
    }
  }, [address])

  useEffect(() => {
    // Generate new message signature after account change
    if (message) {
      signMessage({ message })
    }
  }, [message])

  useEffect(() => {
    if (address && signature && isVerified) {
      // Request BE for token after account change
      dispatch(
        getAuthToken({
          address,
          signature,
        })
      )
    }
  }, [address, signature, isVerified])

  useEffect(() => {
    // reset auth to be ready for new account change
    if (getAuthTokenStatus === 'fulfilled') {
      // Reset message to prevent repeating dispatches on rerender, etc.
      resetMessage()
      dispatch(resetAuth())
    }
  }, [getAuthTokenStatus])

  const connectWallet = () => {
    if (status !== 'disconnected') return
    const metaMaskConnector = connectors.find(item => item.id === 'metaMask')
    if (!metaMaskConnector?.ready) {
      window.open('https://metamask.io', '_blank', 'noreferrer')?.focus()
      return
    }
    connect({ connector: metaMaskConnector })
  }

  const disconnectWallet = () => {
    if (status !== 'connected') return
    disconnect()
  }

  return {
    address,
    chain,
    connectWallet,
    connector,
    disconnectWallet,
    isLoading: status === 'connecting' || status === 'reconnecting' || isMessageLoading,
    status,
  }
}
