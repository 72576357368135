import { FC } from 'react'

import { BorrowerInformation as BorrowerInformationType } from 'models/Borrower'
import { Table as TableType } from 'models/Table'

import { Collapsible, Table } from 'components'

interface BorrowerInformationProps {
  borrowTable: TableType<BorrowerInformationType>
  lendTable: TableType<BorrowerInformationType>
}

export const BorrowerInformation: FC<BorrowerInformationProps> = ({ borrowTable, lendTable }) => {
  const boldTableHeadCols = [0]
  const boldTableBodyCols = [0]

  const { headers: borrowHeaders, data: borrowData } = borrowTable
  const { headers: lendHeaders, data: lendData } = lendTable

  return (
    <div>
      <Collapsible title="Borrower informations">
        <Table
          columns={borrowHeaders}
          data={borrowData}
          headerBoldColsIndexes={boldTableHeadCols}
          bodyBoldColsIndexes={boldTableBodyCols}
        />
        <hr className="my-4 h-px border-primary-30" />
        <Table
          columns={lendHeaders}
          data={lendData}
          headerBoldColsIndexes={boldTableHeadCols}
          bodyBoldColsIndexes={boldTableBodyCols}
        />
      </Collapsible>
    </div>
  )
}
