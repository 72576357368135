import { FC, PropsWithChildren } from 'react'

import * as AlertDialog from '@radix-ui/react-alert-dialog'

import { Button } from 'components/buttons'

import { ReactComponent as Close } from 'assets/icons/close.svg'

interface DialogProps extends PropsWithChildren {
  title: string
  onCancel: () => void
  onAccept: () => void
  cancelButtonLabel?: string
  acceptButtonLabel?: string
}

export const Dialog: FC<DialogProps> = ({
  title,
  acceptButtonLabel,
  cancelButtonLabel,
  onAccept,
  onCancel,
  children,
}) => (
  <AlertDialog.Root defaultOpen>
    <AlertDialog.Portal>
      <AlertDialog.Overlay className="fixed top-0 left-0 h-screen w-screen bg-dialog-overlay backdrop-blur-sm" />
      <AlertDialog.Content className="bg-neutral-0 fixed top-1/2 left-1/2 w-[457px] -translate-y-1/2 -translate-x-1/2 rounded-xl p-8	shadow-dialog	">
        <div className="flex items-start justify-between">
          <AlertDialog.Title className="font-header-h4">{title}</AlertDialog.Title>
          <button type="button" onClick={onCancel}>
            <Close />
          </button>
        </div>
        <div className="mt-10 mb-12">{children}</div>
        <div className="flex w-full items-center justify-between gap-8">
          <Button variant="secondary" onClick={onCancel}>
            {cancelButtonLabel ?? 'Cancel'}
          </Button>

          <Button onClick={onAccept}>{acceptButtonLabel ?? 'OK'}</Button>
        </div>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
)
