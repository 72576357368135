import { FC, useState } from 'react'

import { DropdownMenuItem } from '@radix-ui/react-dropdown-menu'

import { MinMax } from 'models/Form'

import { Button } from 'components/buttons'
import { Dropdown } from 'components/form/dropdown'
import { TextInput } from 'components/form/text-input/TextInput'

interface FilterAprProps {
  onChange: (value: MinMax) => void
  defaultValue: MinMax
}

export const FilterApr: FC<FilterAprProps> = ({ onChange, defaultValue }) => {
  const [filterValue, setFilterValue] = useState<MinMax>(defaultValue)

  const handleClear = () => {
    setFilterValue(defaultValue)
    onChange(defaultValue)
  }

  const handleApprove = () => {
    onChange(filterValue)
  }

  return (
    <Dropdown title={`${filterValue.min}% - ${filterValue.max}%`} label="APR">
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-6">
          <div className="w-[113px]">
            <TextInput
              type="number"
              value={filterValue.min}
              onValueChange={newValue => setFilterValue({ ...filterValue, min: +newValue.value })}
              icon="%"
              placeholder="0"
            />
          </div>
          <span className="font-body-4 font-semibold">to</span>
          <div className="w-[113px]">
            <TextInput
              type="number"
              value={filterValue.max}
              onValueChange={newValue => setFilterValue({ ...filterValue, max: +newValue.value })}
              icon="%"
              placeholder="0"
            />
          </div>
        </div>
        <div className="flex gap-4 self-end">
          <DropdownMenuItem asChild>
            <Button onClick={handleClear} variant="primary" size="small" theme="dark">
              Clear
            </Button>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Button onClick={handleApprove} variant="primary" size="small">
              Apply
            </Button>
          </DropdownMenuItem>
        </div>
      </div>
    </Dropdown>
  )
}
