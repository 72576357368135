import { AccordionElement } from 'models/Accordion'

import { Accordion, Button } from 'components'

import { ReactComponent as ArrowRightIcon } from 'assets/icons/arrowRight.svg'

const accordionElements: AccordionElement[] = [
  {
    value: 'nft',
    header: 'What is NFT?',
    content:
      'A non-fungible token (NFT) is a unique digital identifier that cannot be copied, substituted, or subdivided, that is recorded in a blockchain, and that is used to certify authenticity and ownership.',
  },
  {
    value: 'pricing',
    header: 'How auctions work on Zexus?',
    content:
      'Augue eget arcu dictum varius. Malesuada bibendum arcu vitae elementum curabitur vitae nunc sed velit. Lectus sit amet est placerat in egestas erat. Faucibus a pellentesque sit amet porttitor eget dolor morbi. Pellentesque habitant morbi tristique senectus et netus.',
  },
  {
    value: 'test',
    header: 'What is the fee structure on SuperRare?',
    content:
      'Aliquet bibendum enim facilisis gravida neque convallis a. Ipsum dolor sit amet consectetur adipiscing. Vulputate enim nulla aliquet porttitor lacus luctus. Ullamcorper malesuada proin libero nunc consequat interdum varius. Ipsum a arcu cursus vitae congue mauris rhoncus aenean vel.',
  },
]

export const FaqSection = () => {
  const handleNavigate = () => {
    // TODO: Navigate to FAQ page
  }

  return (
    <div className="bg-secondary-10 py-20">
      <div className="container flex flex-col gap-[50px] text-black sm:flex-row">
        <div className="max-w-[460px]">
          <h2 className="font-header-h1 mb-6 text-third-95">Frequently Asked Questions</h2>
          <p className="font-body-3 mb-6">
            If you still haven’t found what are you looking for reach us on XYZ.
          </p>
          <Button
            onClick={handleNavigate}
            icon={<ArrowRightIcon />}
            theme="dark"
            className="bg-third-95"
          >
            Ask a question
          </Button>
        </div>

        <div className="w-full">
          <Accordion elements={accordionElements} />
          <button
            type="button"
            onClick={handleNavigate}
            className="font-button-1 mt-7 text-third-95"
          >
            SHOW MORE
          </button>
        </div>
      </div>
    </div>
  )
}
