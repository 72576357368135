import { ReactNode } from 'react'

import { ColumnDefinition } from 'models/Table'
import { shortAddress } from 'utils/address'

type TableRowsProps<T> = {
  data: Array<T>
  columns: Array<ColumnDefinition<T>>
  boldColsIndexes?: number[]
}

// <T extends {}> -> https://stackoverflow.com/questions/47848828/how-to-create-stateless-functional-components-that-accept-generics/51585233#51585233
export const TableRows = <T extends {}>({
  data,
  columns,
  boldColsIndexes,
}: TableRowsProps<T>): JSX.Element => {
  const rows = data.map((row, index) => {
    const rowKey = `row-${index}`

    return (
      <tr key={rowKey} className="border-b border-third-80 text-third-10 last:border-0">
        {columns.map((column, colIndex) => {
          const cellKey = `cell-${colIndex}`
          return (
            <td
              key={cellKey}
              className={`font-body-4 py-[21.5px] ${
                boldColsIndexes?.includes(colIndex) ? 'font-semibold' : 'font-normal'
              }`}
            >
              {column.key !== 'id'
                ? (row[column.key] as ReactNode)
                : shortAddress(`0x${row[column.key]}`)}
            </td>
          )
        })}
      </tr>
    )
  })

  return <tbody>{rows}</tbody>
}
