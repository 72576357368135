import { FC } from 'react'

import { Activity } from 'models/Activity'
import { Table as TableType } from 'models/Table'

import { Collapsible, Table } from 'components'

import { ReactComponent as QuestionMarkCircle } from 'assets/icons/questionMarkCircle.svg'

interface ActivitiesProps {
  activities: TableType<Activity>
}

export const Activities: FC<ActivitiesProps> = ({ activities }) => {
  const boldTableBodyCols = [0]

  return (
    <div>
      <Collapsible title="Activities">
        <div className="font-body-4 flex gap-2 text-third-50">
          Recent 60 days Average of the NFT collection
          <QuestionMarkCircle />
        </div>
        <Table
          columns={activities.headers}
          data={activities.data}
          bodyBoldColsIndexes={boldTableBodyCols}
        />
      </Collapsible>
    </div>
  )
}
