import { FC } from 'react'

import { NFT } from 'models/Collateral'
import { Status, TileType } from 'models/Style'
import { UserType } from 'models/User'

import { TileFooter } from './components/TileFooter'
import { TileHeader } from './components/TileHeader'
import { TileImages } from './components/TileImages'

interface TileProps {
  nfts: NFT[]
  type: TileType
  title?: string
  subtitle?: string
  userType?: UserType
  status?: Status
  amount?: number
  currency?: string
  apr?: number
}

export const Tile: FC<TileProps> = ({
  nfts,
  type,
  title,
  subtitle,
  userType,
  status,
  amount,
  currency,
  apr,
}) => {
  let tileClasses = 'flex border border-2 w-[274px] flex-col rounded px-3 py-2'

  if (type === 'collateral') {
    tileClasses += ' bg-primary-95 border-primary-95'
  } else {
    tileClasses += ' bg-third-95 border-third-80'
  }

  return (
    <div className={tileClasses}>
      <TileHeader title={title} subtitle={subtitle} status={status} type={userType} />
      <div className="flex h-[257px] w-full flex-col items-center">
        <TileImages nfts={nfts} />
      </div>
      <TileFooter apr={apr} amount={amount} currency={currency} />
    </div>
  )
}
