import { Collateral, CollateralDetails } from '../../models/Collateral'
import { CollateralsFiltersVal } from '../../screens/collaterals/components/CollateralsFilters'
import { mockedCollateralDetails, mockedCollateralsList } from '../mockups/collaterals'

// TODO: uncomment when endpoint ready
// type GetCollateralsListResponse = {
//   data: Collateral[]
// }

export type GetCollateralsListVars = {
  limit: number
  offset: number
  filters?: CollateralsFiltersVal
}

export type GetCollateralDetailsVars = {
  id: string
}

export const getCollateralsList = async (
  variables: GetCollateralsListVars
): Promise<Collateral[]> => {
  // const { data } = await API.get<GetCollateralsListResponse>(
  //   `${API_URLS.ZEXUS_API}/collaterals/all`
  // )
  // return data.data
  // TODO: remove code below when endpoint ready
  if (variables.limit) {
    return mockedCollateralsList
  }
  return mockedCollateralsList
}

export const getCollateralDetails = async (
  variables: GetCollateralDetailsVars
): Promise<CollateralDetails> => {
  if (variables.id) {
    return mockedCollateralDetails
  }
  return mockedCollateralDetails
}
